#slider-feature-viewer {
  max-width: unset;
  background-color: #1e2019f2;
  margin: 256px auto;
  padding: 96px 0 128px;
}

#slider-feature-viewer h1 {
  color: #35a4cd;
  background-color: #e2efb3;
  margin: 0 auto 128px;
  padding: 16px 32px;
}

#slider-feature-viewer h1 span {
  color: #1e2019;
}

#slider-feature-viewer #slider-cont #slider-images-cont {
  overflow: visible;
}

#slider-feature-viewer #slider-cont #slider-images-cont #slider-images-scroller {
  gap: 64px;
  transition: transform .5s;
  display: flex;
}

#slider-feature-viewer #slider-cont #slider-images-cont #slider-images-scroller .slider-image-cont {
  width: 500px;
  height: 500px;
  flex-shrink: 0;
}

#slider-feature-viewer #slider-cont #slider-images-cont #slider-images-scroller .slider-image-cont.current-col-img img {
  filter: brightness();
}

#slider-feature-viewer #slider-cont #slider-images-cont #slider-images-scroller .slider-image-cont.current-col-img .slider-img-desc {
  opacity: 1;
  transform: translateY(0);
}

#slider-feature-viewer #slider-cont #slider-images-cont #slider-images-scroller .slider-image-cont img {
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: brightness(33%);
  border-radius: 4%;
  transition: filter .5s;
  display: block;
  position: relative;
}

#slider-feature-viewer #slider-cont #slider-images-cont #slider-images-scroller .slider-image-cont .slider-img-desc {
  text-align: center;
  color: #fff;
  opacity: 0;
  margin-top: 32px;
  font-family: Permanent Marker, cursive;
  font-size: 30px;
  transition: transform .5s, opacity .5s;
  transform: translateY(-100px);
}

@media (max-width: 800px) {
  #slider-feature-viewer {
    margin: 128px auto;
  }

  #slider-feature-viewer h1 {
    margin-bottom: 96px;
  }
}

@media (max-width: 500px) {
  #slider-feature-viewer {
    padding-top: 32px;
    padding-bottom: 96px;
  }

  #slider-feature-viewer h1 {
    margin-bottom: 64px;
  }

  #slider-feature-viewer #slider-cont #slider-images-cont #slider-images-scroller .slider-image-cont {
    width: 375px;
    height: 375px;
  }

  #slider-feature-viewer #slider-cont #slider-images-cont #slider-images-scroller .slider-image-cont .slider-img-desc {
    font-size: 26px;
  }
}

/*# sourceMappingURL=index.cad4604a.css.map */
