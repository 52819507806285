@import "/src/constants";

#slider-feature-viewer {
  max-width: unset;
  padding: 96px 0 128px;
  background-color: rgba($dark-chocolate, 0.95);
  margin: 256px auto;

  h1 {
    background-color: $beige;
    margin: 0 auto 128px;
    padding: 16px 32px;
    color: $blue;

    span {
      color: $dark-chocolate;
    }
  }

  #slider-cont {
    #slider-images-cont {
      overflow: visible;

      #slider-images-scroller {
        display: flex;
        gap: 64px;
        transition: 0.5s transform;

        .slider-image-cont {
          width: 500px;
          height: 500px;
          flex-shrink: 0;

          &.current-col-img {
            img {
              filter: brightness(100%);
            }

            .slider-img-desc {
              transform: translateY(0);
              opacity: 1;
            }
          }

          img {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 1;
            filter: brightness(33%);
            transition: 0.5s filter;
            border-radius: $standard-border-radius;
          }

          .slider-img-desc {
            text-align: center;
            color: white;
            margin-top: 32px;
            font-size: 30px;
            font-family: "Permanent Marker", cursive;
            transform: translateY(-100px);
            opacity: 0;
            transition: 0.5s transform, 0.5s opacity;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  #slider-feature-viewer {
    margin: 128px auto;

    h1 {
      margin-bottom: 96px;
    }
  }
}

@media (max-width: 500px) {
  #slider-feature-viewer {
    padding-top: 32px;
    padding-bottom: 96px;

    h1 {
      margin-bottom: 64px;
    }

    #slider-cont {
      #slider-images-cont {
        #slider-images-scroller {
          .slider-image-cont {
            width: 375px;
            height: 375px;

            .slider-img-desc {
              font-size: 26px;
            }
          }
        }
      }
    }
  }
}
